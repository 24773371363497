
import { Component, Prop, Vue } from "nuxt-property-decorator";
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import PlaceHolderCircles from "~/components/molecules/display/PlaceHolderCircles.vue";

@Component({
  components: {
    RouterLink,
    PlaceHolderCircles: () =>
      import("~/components/molecules/display/PlaceHolderCircles.vue"),
  },
})
export default class PlaceListSection extends Vue {
  @Prop() title: string;
  @Prop() limit: number;
  @Prop() items: Array<any>;
  @Prop() listClass: string;
  @Prop() exceeded: boolean;
}
